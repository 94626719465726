<template>
  <div>
    <farmuserSelectedFarms
      ref="farmuserSelectedFarms"
      @GetFarmerFarms="GetFarmerFarms"
    />
    <farmerFarms
      ref="farmerFarms"
      @refreshFarmuserFarms="refreshFarmuserFarms"
    />
  </div>
</template>
  
<script>
import farmuserSelectedFarms from "./subForm/farmuserSelectedFarms";
import farmerFarms from "./subForm/farmerFarms";
export default {
  components: {
    farmuserSelectedFarms,
    farmerFarms,
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    GetFarmerFarms(farmuserId) {
      this.$refs.farmerFarms.GetAllNotAccessFarms(farmuserId);
    },
    refreshFarmuserFarms() {
      this.$refs.farmuserSelectedFarms.RefreshList();
    },
  },
  mounted() {},
  created() {},
};
</script> 


