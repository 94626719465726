<template>
  <div class="farmListBoxViewer">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCallout style="border-right-color: darkgreen; margin-right: 4px">
      <small class="text-success h6"
        >لیست زمین های در دسترس کارمند مزرعه
        <select class="userList" v-model="farmuserId">
          <option value="0" selected disabled>کارمند مزرعه را انتخاب کنید</option>
          <option
            v-for="(item, index) of farmuserList"
            :key="index"
            :value="item.farmuserId"
          >
            {{ item.fullName }}
          </option>
        </select>
      </small>
      <br />
      <strong class="h6 text-danger" v-if="farmuserId > 0"
        >کارمند مزرعه {{ farmuserFarmList.length }} زمین دارد</strong
      >
    </CCallout>
    <div class="farm-list-box">
      <div
        v-if="farmuserFarmList.length > 0"
        style="padding-bottom: 1em; float: right"
      >
        <div
          class="farmItem"
          v-for="(item, index) in farmuserFarmList"
          :key="index"
        >
          <img
            class="farmImg"
            :src="item.image"
            @error="
              () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
            "
          />
          <img
            class="productImg"
            @error="
              () => (item.productImage = require(`@/assets/img/notFound.png`))
            "
            :src="item.productImage"
            alt=""
          />
          <ul>
            <li>{{ item.title }}</li>
            <li>{{ item.product }}</li>
            <li>{{ item.stateCity }}</li>
            <li>{{ item.districtVilage }}</li>
          </ul>
          <button
            @click="DeleteFarmuserFarm(item.id, item.title)"
            class="farmItemBtn btn-Add"
          >
            حذف دسترسی کارمند مزرعه
            <i class="fa fa-check" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div v-else class="emptyBox">
        <h6 v-if="farmuserId > 0">کارمند مزرعه هیچ زمینی ندارد</h6>
        <h6 v-else style="margin-right: 5em; color: #bbb">
          کارمند مزرعه را از لیست بالا انتخاب کنید
        </h6>
      </div>
    </div>
  </div>
</template>

  <script>
import { apiUrlRoot } from "../../../../constants/config";
import { mapActions } from "vuex";
export default {
  name: "farmuserSelectedAllFarms",
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      farmuserId: 0,
      farmuserList: [],
      farmuserFarmList: [],
      rootPat: apiUrlRoot,
    };
  },
  watch: {
    farmuserId: function () {
      this.GetAllFarmuserFarms();
    },
  },
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "GetAllFarmerFarmuser",
      "FarmerFarmuserFarms",
      "FarmuserDeleteFarm",
    ]),

    async GetAllFarmuser() {
      this.farmuserId = 0;
      let result = await this.GetAllFarmerFarmuser({
        searchName: "",
      });
      this.farmuserList = result.data.map((item) => {
        return {
          farmuserId: item.farmuserId,
          fullName: `${item.fullName} (${item.phone})`,
        };
      });
    },
    async GetAllFarmuserFarms() {
      this.farmuserFarmList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd: false,
      });
      this.farmuserFarmList = result.data;
      this.farmuserFarmList.forEach((element) => {
        element.image = `${apiUrlRoot}Farm/${element.image}`;
        element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
      });
      this.loading = false;
      this.$emit("GetFarmerFarms", this.farmuserId);
    },
    async RefreshList() {
      this.farmuserFarmList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd: false,
      });
      this.loading = false;
      this.farmuserFarmList = result.data;
    },
    async DeleteFarmuserFarm(id, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را از دسترس کارمند مزرعه خارج میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.FarmuserDeleteFarm({
              id: id,
            });
            if (result.succeeded) {
              this.GetAllFarmuserFarms();
            } else {
              this.loading = true;
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
          }
        },
      });
    },
    getImagePath(fileName, path) {
      return `${apiUrlRoot}${path}/${fileName}`;
    },
  },
  mounted() {
    this.GetAllFarmuser();
  },
};
</script>

  <style scoped>
.farmListBoxViewer {
  float: right;
  margin-top: 2vh;
  margin-right: 1vw;
  height: 86vh;
  width: 48%;
  -webkit-box-shadow: 0px 0px 4px 2px #bbb;
  box-shadow: 0px 3px 9px -2px #bbb;
  background-color: #fff;
  border-radius: 9px;
}
.farm-list-box {
  width: 98%;
  height: 76vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0.5em;
}
.bodyItem {
  float: right;
  clear: both;
  padding: 6px;
  min-height: 25vh;
}

.bodyItemValue {
  float: right;
  clear: both;
  border-right: 1px solid #a196fb;
  margin-top: 3px;
  padding-right: 6px;
  padding-top: 9px;
  background-color: #efefef;
  width: 100%;
}

.userList {
  width: 13vw;
  border: none;
  border-bottom: 1px solid darkgreen;
  color: darkgreen;
  outline: none;
}

.farmItem {
  width: 18vw;
  float: right;
  border-radius: 2em;
  background-color: #fff;
  margin: 1em;
  border: 1px solid #bbb;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px #000;
  position: relative;
  height: 37vh;
}

.farmItem .farmImg {
  float: right;
  width: 90%;
  height: 20vh;
  margin: 5%;
}
.farmItem .productImg {
  width: 10%;
  position: absolute;
  bottom: 10em;
  left: 2em;
}

.farmItem ul {
  float: right;
  margin: 0em;
  width: 100%;
  list-style: none;
  padding: 0em 0.5em;
}

.farmItem ul li {
  float: right;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid #03c503;
  margin: 1px 0px;
  padding-right: 0.4em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  border-radius: 0px 4px 4px 0px;
}

.farmItemBtn {
  width: 14.6vw;
  height: 2.5vh;
  text-align: center;
  font-size: 13px;
  padding: 1px 1px 0px 0px;
  border: none;
  color: rgb(255 255 255);
  bottom: -1%;
  position: absolute;
  background-color: #c50303;
  right: 2em;
  border-radius: 0px 0px 10px 10px;
  transition: all 500ms;
}
.farmItemBtn:hover {
  box-shadow: 0px 7px 16px -4px #000;
}
.emptyBox h6 {
  font-size: 2em;
  color: #ffc037;
  margin-top: 10em;
  margin-right: 7em;
  font-family: inherit;
}
</style>
