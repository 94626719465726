<template>
  <div class="farmListBoxViewer">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCallout style="border-right-color: #8679ff; margin-right: 4px">
      <small class="h5" style="color: #8679ff">
        لیست زمین ها ({{ farmerFarms.length }})</small
      >
      <br />
      <strong class="h6" style="color: #bbb"
        >لیست زمین هایی که در دسترس کارمند مزرعه انتخاب شده نیستند</strong
      >
    </CCallout>
    <div class="farm-list-box">
      <div
        v-if="farmerFarms.length > 0"
        style="padding-bottom: 1em; float: right"
      >
        <div
          class="farmItem"
          v-for="(item, index) in farmerFarms"
          :key="index"
        >
          <img
            class="farmImg"
            :src="item.image"
            @error="
              () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
            "
          />
          <img
            class="productImg"
            @error="
              () => (item.productImage = require(`@/assets/img/notFound.png`))
            "
            :src="item.productImage"
            alt=""
          />
          <ul>
            <li>{{ item.title }}</li>
            <li>{{ item.product }}</li>
            <li>{{ item.stateCity }}</li>
            <li>{{ item.districtVilage }}</li>
          </ul>
          <button
            @click="AddFarmuserFarm(item.farmId, item.title)"
            class="farmItemBtn btn-Add"
          >
            افزودن زمین به کارمند مزرعه
            <i class="fa fa-check" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div v-else class="emptyBox">
        <h6 v-if="farmuserId > 0" style="font-size: 1.5em">
          کارمند مزرعه به تمام زمین های ممکن دسترسی دارد
        </h6>
        <h6 v-else style="color: #bbb">کارمند مزرعه را انتخاب کنید</h6>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../../constants/config";
export default {
  name: "farmerFarmuserAllFarms",
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      farmerFarms: [],
      farmuserId: 0,
    };
  },
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "FarmerFarmuserFarms",
      "FarmuserAddFarm",
    ]),
    async GetAllNotAccessFarms(farmuserId) {
      this.farmuserId = farmuserId;
      this.farmerFarms = [];
      this.sumAreas = 0;
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd: true,
      });
      this.farmerFarms = result.data;
      if (this.farmerFarms.length > 0) {
        this.farmerFarms.forEach((element) => {
          element.image = `${apiUrlRoot}Farm/${element.image}`;
          element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
        });
      }
      this.loading = false;
    },
    async AddFarmuserFarm(farmId, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را به کارمند مزرعه می دهید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.FarmuserAddFarm({
              farmuserId: this.farmuserId,
              farmId: farmId,
            });
            if (result.succeeded) {
              this.GetAllNotAccessFarms(this.farmuserId);
              this.$emit("refreshFarmuserFarms");
            } else {
              this.loading = true;
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
          }
        },
      });
    },
  },
  mounted() {},
};
</script>

  <style scoped>
.farmListBoxViewer {
  float: right;
  margin-top: 2vh;
  margin-right: 1vw;
  height: 86vh;
  width: 48%;
  -webkit-box-shadow: 0px 0px 4px 2px #bbb;
  box-shadow: 0px 3px 9px -2px #bbb;
  background-color: #fff;
  border-radius: 9px;
}
.farm-list-box {
  width: 98%;
  height: 76vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0.5em;
}
.farmItem {
  width: 18vw;
  float: right;
  border-radius: 2em;
  background-color: #fff;
  margin: 1em;
  border: 1px solid #bbb;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px #000;
  position: relative;
  height: 37vh;
}

.farmItem .farmImg {
  float: right;
  width: 90%;
  height: 20vh;
  margin: 5%;
}
.farmItem .productImg {
  width: 10%;
  position: absolute;
  bottom: 10em;
  left: 2em;
}

.farmItem ul {
  float: right;
  margin: 0em;
  width: 100%;
  list-style: none;
  padding: 0em 0.5em;
}

.farmItem ul li {
  float: right;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid #03c503;
  margin: 1px 0px;
  padding-right: 0.4em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  border-radius: 0px 4px 4px 0px;
}

.farmItemBtn {
  width: 14.6vw;
  height: 2.5vh;
  text-align: center;
  font-size: 13px;
  padding: 1px 1px 0px 0px;
  border: none;
  color: rgb(255 255 255);
  bottom: -1%;
  position: absolute;
  background-color: #06d506;
  right: 2em;
  border-radius: 0px 0px 10px 10px;
  transition: all 500ms;
}
.farmItemBtn:hover {
  box-shadow: 0px 7px 16px -4px #000;
}
.emptyBox h6 {
  font-size: 2em;
  color: #ffc037;
  margin-top: 10em;
  margin-right: 7em;
  font-family: inherit;
}
</style>
